'use strict';

// Imports.
import initializeConfig from '../initialize-config';
import { ethersService } from './index';
import { ethers } from 'ethers';
import axios from 'axios';
import { OpenSeaPort, Network } from 'opensea-js';
import { Multicall, ContractCallResults, ContractCallContext } from 'ethereum-multicall';

import { OrderSide } from 'opensea-js/lib/types.js'

// Initialize this service's configuration.
let config;
(async () => {
  config = await initializeConfig();
})();

const sleep = function (ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
};

const getSeaport = async function(){
  let web3Provider = await ethersService.getWeb3jsProvider();
  let chainId =  await web3Provider.eth.getChainId();

  let accts = await web3Provider.eth.getAccounts()
  console.log("connected web3",accts[0], web3Provider.eth)
  //let openseaAPIKey = config.openseaAPIKey;
  let network = ( chainId == 1 ) ? Network.Main : Network.Rinkeby ;
  console.log("network", network, chainId)
  const seaport = new OpenSeaPort(web3Provider.currentProvider, {
    networkName: network,
    apiKey: config.openseaAPIKey
  });

  return seaport
}

const getAsset = async function(tokenAddress, tokenId) {
  //console.log('triggered asset', asset);
  let seaport = await getSeaport();
  const asset = await seaport.api.getAsset({
    tokenAddress: tokenAddress,
    tokenId: tokenId
  });

  let contractData = await getAssetContract(tokenAddress);
  let slug = contractData.collection.slug;
  let assetCollection = await getAssetCollection(slug);

  let metadata = {
    id: tokenId,
    name: (asset.name) ? asset.name : `# ${asset.tokenId}`,
    image: asset.imageUrl,
    description: asset.description,
    traits: asset.traits,
    slug: slug,
    price: (asset.sellOrders.length > 0) ? ethers.utils.formatEther(asset.sellOrders[0].basePrice.toString()) : 0,
    contractData: contractData,
    collectionData: assetCollection
  }

  let orders = await getOrders(tokenAddress, tokenId);

  console.log('triggered contractData', contractData);
  let fullMetaAsset = {
    //metadataUri: itemMetadataUri,
    metadata: metadata,
    orders: orders,

    //groupId: item.groupId,
    //cap: item.cap,
    //minted: item.minted,
    //prices: itemPrices
  }

  //let orders = await getAssetOrders(tokenAddress, tokenId);

  return [fullMetaAsset];
};

const getAssetsCollection = async function(tokenAddress, tokenId, page) {
  console.log('getAssetsCollection', tokenId, page);
  let limit = 50;
  let offset = (page) ? page * limit : 0;
  //let offset = 0;
  let seaport = await getSeaport();
  let query = {
    asset_contract_address: tokenAddress,
    offset: offset,
    limit: limit,
    order_by: 'sale_price',
    order_direction: 'asc'
  }

  if(tokenId){
    query.token_ids = tokenId;
  }
  console.log("query", query);
  let collectionAssets = await seaport.api.getAssets(query);
  let assets = [];
  console.log("ret assets", collectionAssets);

  if(collectionAssets.assets.length == 0){
    collectionAssets = await seaport.api.getAssets({
      asset_contract_address: tokenAddress,
      offset: offset,
      limit: limit
    });
  }
  for(let asset of collectionAssets.assets) {
    if(asset.sellOrders){
      console.log("SELL", asset.sellOrders[0].basePrice.toString())

      let fullMetaAsset = {
        metadata: {
          id: asset.tokenId,
          name: (asset.name) ? asset.name : `# ${asset.tokenId}`,
          image: asset.imageUrl,
          description: asset.description,
          traits: asset.traits,
          price: ethers.utils.formatEther(asset.sellOrders[0].basePrice.toString())
        },
        orders: asset.sellOrders[0]
      }
      assets.push(fullMetaAsset);
    }
  }

  return assets;
};

const fillOrder = async function (order, accountAddress, dispatch) {
  let seaport = await getSeaport();
  // let web3Provider = await ethersService.getWeb3jsProvider();
  // const accountAddress = web3Provider.eth.accounts[0];
  //
  console.log("fillOrder", order, accountAddress)
  const referrerAddress = accountAddress;
  const recipientAddress = accountAddress;
  //const transactionHash = await seaport.fulfillOrder({ order, accountAddress, recipientAddress, referrerAddress })
  try{
    const transactionHash = await seaport.fulfillOrder({ order: order, accountAddress})
    console.log("transactionHash", accountAddress)
  } catch( error) {
    await dispatch('alert/error', error, { root: true });
    console.log("error", error)
  }


};

const getMultiCallInstance = async function (tryAggregate) {
  let web3 = await ethersService.getWeb3jsProvider();
  const multicall = new Multicall({ web3Instance: web3, tryAggregate: tryAggregate });
  return multicall;
}

const getOrders = async function (tokenAddress, tokenIds) {
  let seaport = await getSeaport();
  let twoDaysAgo = new Date();
  twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);

  const orders = await seaport.api.getOrders({
    asset_contract_address: tokenAddress,
    token_id: tokenIds,
    side: OrderSide.Sell
    //listed_after: Math.floor(twoDaysAgo / 1000)
  });

  console.log("orders", tokenAddress, orders)
  return orders.orders;
};

const getAssetsByOrders = async function (tokenAddress, tokenIds){
  let orders = await getOrders(tokenAddress, tokenIds);
  let contractData = await getAssetContract(tokenAddress);

  let assets = [];
  let lowestPrice = 100000000;
  let fullMetaAsset;
  for(let order of orders){
    if(ethers.utils.formatEther(order.basePrice.toString()) < lowestPrice){
      lowestPrice = ethers.utils.formatEther(order.basePrice.toString())
      fullMetaAsset = {
        metadata: {
          id: order.asset.tokenId,
          name: (order.asset.name) ? order.asset.name : `# ${order.asset.tokenId}`,
          image: order.asset.imageUrl,
          description: order.asset.description,
          traits: order.asset.traits,
          price: ethers.utils.formatEther(order.basePrice.toString()),
          contractData: contractData
        }
      }
    }
    //assets.push(fullMetaAsset);
  }
  return [fullMetaAsset];
};

const getAssetContract = async function (tokenAddress){
  let seaport = await getSeaport();
  const assetContractData = await seaport.api.get(`/api/v1/asset_contract/${tokenAddress}`, {});
  return assetContractData;
};

const getAssetCollection = async function (slug){
  let seaport = await getSeaport();
  const assetCollectionData = await seaport.api.get(`/api/v1/collection/${slug}`, {});
  return assetCollectionData;
};

const getAssetOrders = async function(tokenAddress, tokenId) {
  let seaport = await getSeaport();
  await sleep(3000);
  let orders = await seaport.api.getOrders({
    asset_contract_address: tokenAddress,
    token_id: tokenId,
    side: '0'
  //}, 2);
  });
  console.log({orders:orders})
  return orders;
};

// Export the sweep service functions.
export const seaportService = {
  getAsset,
  getAssetsCollection,
  getAssetsByOrders,
  fillOrder,
  getOrders
};
