'use strict';

// Specified state transitions per particular actions.
export default {

  // Update the list of launchpad items being tracked for display purposes.
  updateAssets(state, assets) {
    state.assets = assets;
  }
};
