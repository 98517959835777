// Imports.
import { createRouter, createWebHistory } from 'vue-router';

// Page imports.
import Home from '../pages/home/Home.vue';
import Asset from '../pages/asset/Asset.vue';
import Collection from '../pages/collection/Collection.vue';
import Sweep from '../pages/sweep/Sweep.vue';
import MyFrens from '../pages/my-frens/MyFrens.vue';
import Terms from '../pages/terms/Terms.vue';
import NotFound from '../pages/not-found/NotFound.vue';

// Create routes.
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/asset',
    name: 'Asset',
    component: Asset,
    props: route => ({ ...route.query })
  },
  {
    path: '/collection',
    name: 'Collection',
    component: Collection
  },
  {
    path: '/sweep',
    name: 'Sweep',
    component: Sweep
  },
  {
    path: '/my-frens/:id',
    name: 'My Frens',
    component: MyFrens
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms
  },
  { path: '/:pathMatch(.*)*', component: NotFound }
];

// Set up the Vue router to operate in web history mode.
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});
export default router;
