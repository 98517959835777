'use strict';

// Imports.
import { seaportService } from '../../services';

// Specified actions for mutating the alert state.
export default {
  // Use the mint service to get a list of available shop items.
  async getAsset({ dispatch, commit }, { tokenAddress, tokenId }) {
    try {
      let assets = await seaportService.getAsset( tokenAddress, tokenId );
      console.log("getAsset", assets)
      commit('updateAssets', assets);

      // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async getAssetsCollection({ dispatch, commit }, { tokenAddress, tokenId, page }) {
    try {
      let assets = await seaportService.getAssetsCollection( tokenAddress, tokenId, page );
      commit('updateAssets', assets);

      // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async getOrders({ dispatch, commit }, { tokenAddress, tokenIds }) {
    try {
      let assets = await seaportService.getAssetsByOrders( tokenAddress, tokenIds );
      commit('updateAssets', assets);

      // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async fillOrder({ dispatch, commit }, { order, accountAddress }) {
    try {
      let filled = await seaportService.fillOrder( order, accountAddress, dispatch );
      //commit('updateAssets', assets);

      // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },
};
